  .progressMain {
    flex-direction: row;
    position: relative;
    display: flex;
    padding: 0;

    .progressDiv {
      position: relative;
      width: 30px;
      height: 30px;
      border-radius: 50%;

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: inherit;
        border-radius: inherit;
        animation: wave 1.5s ease-out infinite;
      }
  
      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          &::before {
            animation-delay: $i * 0.2s;
          }
        }
      }
    }
  }
  
  @keyframes wave {
    50%,
    75% {
      transform: scale(2.5);
    }
  
    80%,
    100% {
      opacity: 0;
    }
  }

  @media only screen and (max-width: 1250px) {
    .progressMain {
      flex-direction: column-reverse;
  }
  }