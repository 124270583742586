#mainDiv{
    margin-top: 50px;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
}
.alignRanks{
    margin: 35px auto 50px;
    display: flex;
    flex-direction: row;
}
#jobTypeDiv{
    margin: 40px auto 0;
}
.ranksMainDiv{
    display: flex;
    flex-direction: column;
}
.singleRank{
    display: flex;
    align-items: center;
    flex-direction: row;
}
.rankLevel{
    color: white;
}
.rankDivBnW, .rankDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.rankDiv{
    background-color: #43C6A5;
}
.rankDivBnW{
    background-color: #5a5f73;
}
.rankFont{
    font-size: 20px;
    max-width: 200px;
    height: 26px;
    // border: ridge;
    overflow: hidden;
}
.rankFontDiv{
    margin-top: 40px;
}

@media only screen and (max-width: 1250px) {
    .alignRanks{
        flex-direction: column-reverse;
        width: 50%;
    }
.ranksMainDiv{
    // border: ridge;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.rankFont{
    max-width: auto;
    height: auto;
    overflow: auto;
    margin-bottom: 30px;
    // border: ridge;
}
.singleRank{
    flex-direction: column-reverse;
}
.rankFontDiv{
    // border: ridge;
    display: flex;
    align-items: flex-end;
}
}
@media only screen and (max-width: 900px) {
    .alignRanks{
        width: 70%;
    }
  }
@media only screen and (max-width: 430px) {
    .alignRanks{
        width: 80%;
    }
    .rankFont{
        margin-bottom: 25px;
    }
}